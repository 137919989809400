import $ from 'jquery';

var InterconnectConfiguration = (function() {
  function gen_keypair() {
    var input=$(this);
    $.ajax({type:'POST', url:input.data('url')}).done(function(data) {
      $('#interconnect_configuration_rsa_public_key').val(data.public_key);
      $('#interconnect_configuration_rsa_private_key').val(data.private_key);
    }).fail(function( jqXHR, textStatus, errorThrown ) {
      console.log(jqXHR, textStatus, errorThrown); // eslint-disable-line no-console
      input.popover('show');
    })
  }

  return {
    gen_keypair:gen_keypair
  };
})();


$(document).on('click', '#gen_key_pair', InterconnectConfiguration.gen_keypair);
